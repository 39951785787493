
export enum StepTrainingRussianLanguage{
    remembersWords = 1,
    pronunciationWords = 2,
    imageWords = 3,
    imageAudio = 4,
    remembersCoding = 5,
}

export interface TrainingMemoryRussianCreateViewModel
{
    timeOfElement?: bigint;
    languageVocabularyType: number;
    group: string;
    category: string;
    systemCoding?: string;
    rateCoding?: string;
    stage: TrainingMemoryRussianStage;
    step: StepTrainingRussianLanguage;
}

export enum TrainingMemoryRussianStage
{
    FormationLibrary = 1,
    IncodeCoding = 2,
}
export interface TrainingMemoryRussianMemberViewModel {
    id: bigint;
    step: StepTrainingRussianLanguage;
    stage: TrainingMemoryRussianStage;
    items: TrainingMemoryRussianMemberItemViewModel[]
}

export interface TrainingMemoryRussianMemberItemViewModel {
    id: bigint;
    image: string;
    nameRus: string;
    nameEng: string;
    transcriptionAmerica: string
    translationEng: string
    translationRus: string
    audio: string;
    answers?: string[]
    itemType: AdjectiveTrainingItemType
}
export enum AdjectiveTrainingItemType{
    Member = 0,
    SelectAnswer = 1,
    Audio = 2,
    Image = 3,
    Name = 4,
}


export interface TrainingMemoryRussianMemberRequestViewModel {
    id: bigint;
    items: TrainingMemoryRussianMemberItemRequestViewModel[]
}

export interface TrainingMemoryRussianMemberItemRequestViewModel {
    id: bigint;
    timeElement: number;
    answer: string;
    tryCount: number
}

export interface TrainingMemoryRussianWriteViewModel {
    id: bigint;
    items: TrainingMemoryRussianWriteItemViewModel[];
}

export interface TrainingMemoryRussianWriteItemViewModel {
    id: bigint;
    image: string;
    answers: string[];
    correctAnswer: string;
}

export interface TrainingMemoryRussianWriteRequestViewModel {
    id: bigint;
    items: TrainingMemoryRussianWriteRequestItemViewModel[]
}

export interface TrainingMemoryRussianWriteRequestItemViewModel {
    id: bigint;
    timeElement: number;
    answer: string;
}

export interface TrainingMemoryRussianResultViewModel {
    id: bigint;
    speed: number;
    timeAll: number;
    number: number;
    error: number;
    createTime: string;
    username: string;
    imgSrc: string;
    rang: string;
    link: string;
    search: boolean;
    isPremium: boolean;
    stageName: string;
    stepName: string;

    items: TrainingMemoryRussianResultItemViewModel[]
}

export interface TrainingMemoryRussianResultItemViewModel {
    id: bigint;
    answerTimeElement: number;
    correct: boolean;
    correctAnswer: string;
    userAnswer: string;
    itemType: AdjectiveTrainingItemType;
}
// public long Id { get; set; }
// public long? AnswerTimeElement { get; set; }
// public bool Correct { get; set; }
// public string CorrectAnswer { get; set; }
// public string UserAnswer { get; set; }
// public AdjectiveTrainingItemType ItemType { get; set; }
