import {makeAutoObservable} from "mobx";
import {CodingMemberModel, CodingObjectItemModel, CodingRequestMemberModel} from "../model/CodingMemberModel";
import React from "react";
import {replaceImage} from "../../../helpers/img/imgHelp";
import {Loading} from "../../shared/Loading";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";


export default class TrainingSportCodingWriteStore {
    model: CodingMemberModel
    countElementsOneTime: number = 1;
    numberElNow: number = 0;
    elementAtGo: number = 1;
    showHint: boolean = false;
    lastDate: Date = new Date();
    finishTraining: (model: CodingRequestMemberModel) => void;

    timer: NodeJS.Timer | undefined = undefined;

    timerShowHint: NodeJS.Timer | undefined = undefined;
    numberCount: number = 0;

    constructor(finishTraining: (model: CodingRequestMemberModel) => void,
                model: CodingMemberModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.finishTraining = finishTraining;
        this.model = model;
    }

    init() {
        KeyboardManager.add('ArrowLeft', this.leftOrBack);
        KeyboardManager.add('ArrowRight', this.rightOrNext);
        KeyboardManager.add('ArrowUp', this.clickHint);
        KeyboardManager.add('ArrowDown', this.showMultipleTimesHint);

        if (this.model.timeElement)
            this.timer = setInterval(this.next, this.model.timeElement);
    }

    unmount() {
        KeyboardManager.remove('ArrowLeft', this.leftOrBack);
        KeyboardManager.remove('ArrowRight', this.rightOrNext);
        KeyboardManager.remove('ArrowUp', this.clickHint);
        KeyboardManager.remove('ArrowDown', this.showMultipleTimesHint);

        if (this.model.timeElement)
            clearInterval(this.timer);
        this.clearInt();
    }

    rightOrNext() {
        if (this.model.styleCoding === 'X2' || this.model.styleCoding === 'image2') {
            this.right()
        } else {
            this.next();
        }
    }

    leftOrBack() {
        if (this.model.styleCoding === 'X2' || this.model.styleCoding === 'image2') {
            this.left()
        } else {
            this.back();
        }
    }

    left() {
        this.model.list[this.numberElNow].answer = this.model.list[this.numberElNow].leftAnswer;
        this.next();
    }

    right() {
        this.model.list[this.numberElNow].answer = this.model.list[this.numberElNow].rightAnswer;
        this.next();
    }

    next() {
        if (this.numberElNow < 0) return;
        this.clearInt();
        this.showHint = false;
        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.model.list.length && (elDate % this.countElementsOneTime !== 0 || firsStart === true);
               elDate++) {
            firsStart = false;
            if (!this.model.list[elDate].interval)
                this.model.list[elDate].interval = 0;
            this.model.list[elDate].interval += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
            this.model.list[elDate].date = new Date().getTime();
        }
        this.numberElNow += this.countElementsOneTime;
        this.lastDate = new Date();
        if (this.numberElNow >= this.model.list.length) {
            this.finishTrainingClick();
        }
    }

    back(): void {
        if (this.numberElNow <= 0) {
            return;
        }
        this.clearInt();
        this.showHint = false;
        let firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.model.list.length && (elm1 % this.countElementsOneTime !== 0 || firsStart === true);
               elm1++) {
            firsStart = false;
            if (!this.model.list[elm1].interval)
                this.model.list[elm1].interval = 0;
            this.model.list[elm1].interval += (new Date().getTime() - this.lastDate.getTime()) / countNow;
            this.model.list[elm1].date = new Date().getTime();
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        this.lastDate = new Date();
    }

    clickHint() {
        this.showHint = !this.showHint;
    }

    intervalTimeHint() {
        this.clickHint();
        this.numberCount++;
        if (this.numberCount >= 4) {
            this.clearInt();
        }
    }

    clearInt() {
        clearInterval(this.timerShowHint);
        this.timerShowHint = undefined;
    }

    showMultipleTimesHint() {
        if (this.timerShowHint)
            return;
        this.numberCount = 0;
        this.clickHint();
        this.timerShowHint = setInterval(this.intervalTimeHint, 800);
    }


    get view(): JSX.Element {
        if (this.model.list.length <= this.numberElNow)
            return <Loading/>
        let model: CodingObjectItemModel = this.model.list[this.numberElNow];
        return <div className="coding-view">
            {
                (this.model.styleCoding === 'XClean' || this.model.styleCoding === 'X2') &&
                <>
                    {
                        this.model.styleCoding === 'X2' &&
                        <div className="cod-1 cod-l"
                             style={{
                                 backgroundImage: 'url(https://powermemory.io' + replaceImage(
                                     this.getLinkingImg(model, model.leftAnswer)) + ')'
                             }}></div>
                    }
                    <div className="cod-num" onClick={this.clickHint}>{model.linkingTitle}</div>
                    {
                        this.showHint &&
                        <div className="cod-num-image" onClick={this.clickHint}
                             style={{
                                 backgroundImage: 'url(https://powermemory.io' + replaceImage(
                                     model.linkingImg) + ')'
                             }}></div>
                    }
                    <div className="cod-name-hint" onClick={this.clickHint}></div>

                    {
                        this.model.styleCoding === 'X2' &&
                        <div className="cod-2 cod-r"
                             style={{
                                 backgroundImage: 'url(https://powermemory.io' + replaceImage(
                                     this.getLinkingImg(model, model.rightAnswer)) + ')'
                             }}>{}</div>
                    }
                </>
            }
            {
                (this.model.styleCoding === 'imageClean' || this.model.styleCoding === 'image2') &&
                <>
                    {
                        this.model.styleCoding === 'image2' &&
                        <div className="cod-name21">{model.leftAnswer}</div>
                    }
                    <div className="cod-centr" onClick={this.clickHint}
                         style={{
                             backgroundImage: 'url(https://powermemory.io' +
                                 replaceImage(model.linkingImg) + ')'
                         }}></div>
                    {
                        this.showHint &&
                        <div className="cod-name-hint" onClick={this.clickHint}>
                            <span className="cod-name-hint-span">{model.linkingTitle}</span>
                        </div>
                    }
                    {
                        this.model.styleCoding === 'image2' &&
                        <div className="cod-name22">{model.rightAnswer}</div>
                    }
                </>
            }
        </div>
    }

    getLinkingImg(model: CodingObjectItemModel, element: string) {
        if (element === model.linkingTitle) {
            return model.linkingImg;
        } else {
            return model.linkingErrorImg
        }
    }

    get positionInfo(): string {
        return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
            " / " + Math.ceil(this.model.list.length / this.countElementsOneTime);
    }

    finishTrainingClick() {
        this.finishTraining({
            idTr: this.model.idTr,
            items: this.model.list
        });
    }

    fastFinishTrainingClick() {
        this.finishTraining({
            idTr: this.model.idTr,
            items: this.model.list.slice(0, this.numberElNow)
        });
    }
}