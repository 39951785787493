import {CustomComponent} from "../../../helpers/CustomComponent";
import {CustomButton} from "../../../helpers/CustomButton";
import React from "react";
import {observer} from "mobx-react";

interface Props {
    answer: string;
    checkAnswer: boolean | undefined;
    click: (answer: string) => void;
    disabled?: boolean;
}
@observer
export class ChooseAnswerTrainingLearnLanguage extends CustomComponent<Props, any> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.click(this.props.answer);
    }

    get validAnswer() {
        if (this.props.checkAnswer === true) {
            return "correct-answer"
        }
        if (this.props.checkAnswer === false) {
            return "bad-answer"
        }
        return "";
    }

    get disableAnswer() {
        if (this.props.disabled === true) {
            return "disabled"
        }
        return "";
    }

    render() {
        return <CustomButton className={"choose-answer " + this.validAnswer + " " +this.disableAnswer}
                             onclick={this.click} text={this.props.answer}/>;
    }
}