import {CustomComponent} from "../../helpers/CustomComponent";
import React from "react";
import {PostsStore} from "./store/PostsStore";
import './posts.css'
import {SmallPosts} from "./SmallPosts";
import {observer} from "mobx-react";
import {Loading} from "../shared/Loading";


@observer
export class Posts extends CustomComponent<{}, {}>{
    store: PostsStore = new PostsStore();

    render() {
        return (
            <section className="news-bords clear-fix">
                {
                     this.store.model.Model && this.store.model.Model.map((x) =>
                        <SmallPosts key={x.id} post={x}/>
                    )
                }
                {/*// todo Добавить скелетонс*/}
                {/*{*/}
                {/*    !this.store.model.IsLoaded && <Loading />*/}
                {/*}*/}

                <label className="more" htmlFor="more-news" onClick={this.store.somePosts}>ещё</label>
            </section>
        )
    }
}