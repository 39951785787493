import {makeAutoObservable} from "mobx";
import React from "react";
import {Loading} from "../../shared/Loading";
import {
    AdjectiveTrainingItemType,
    TrainingMemoryRussianMemberItemRequestViewModel,
    TrainingMemoryRussianMemberViewModel,
    TrainingMemoryRussianStage, TrainingMemoryRussianWriteRequestViewModel
} from "../model/LearnLanguageMemberModel";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {AudioComponent} from "../../../helpers/audio/AudioComponent";
import {CheckTextComponent} from "../../../helpers/checkText/CheckTextComponent";
import {TitleComponent, TypePositionText, TypeTitle} from "../../../helpers/title/TitleComponent";
import {ImageComponent} from "../../../helpers/img/ImageComponent";
import {ChooseAnswerTrainingLearnLanguage} from "../components/ChooseAnswerTrainingLearnLanguage";


export default class TrainingLearnLanguageMemberStore {
    model: TrainingMemoryRussianMemberViewModel
    itemsRequest: TrainingMemoryRussianMemberItemRequestViewModel[] = [];
    countElementsOneTime: number = 1;
    numberElNow: number = 0;
    elementAtGo: number = 1;
    showHint: boolean = false;
    lastDate: Date;
    modalClose: boolean = false;
    finishTraining: (model: TrainingMemoryRussianWriteRequestViewModel) => void;

    timer: NodeJS.Timer | undefined = undefined;

    timerAnswer: NodeJS.Timer | undefined = undefined;
    isClickAnswer: boolean = false;


    openModal() {
        this.modalClose = true;
    }

    closeModal() {
        this.modalClose = false;
    }

    openCloseModal() {
        if (this.modalClose) {
            this.closeModal()
        } else {
            this.openModal()
        }
    }

    constructor(finishTraining: (model: TrainingMemoryRussianWriteRequestViewModel) => void,
                model: TrainingMemoryRussianMemberViewModel) {
        makeAutoObservable(this, {}, {autoBind: true});
        this.finishTraining = finishTraining;
        this.model = model;
        for (let i = 0; i < this.model.items.length; i++) {
            this.itemsRequest.push({
                id: this.model.items[i].id,
                timeElement: 0,
                answer: "",
                tryCount: 0
            })
        }
        this.lastDate = new Date();
    }

    init() {
        KeyboardManager.add('ArrowLeft', this.back);
        KeyboardManager.add('ArrowRight', this.next);
        KeyboardManager.add('ArrowUp', this.clickHint);
    }

    unmount() {
        KeyboardManager.remove('ArrowLeft', this.back);
        KeyboardManager.remove('ArrowRight', this.next);
        KeyboardManager.remove('ArrowUp', this.clickHint);
    }

    setUserAnswer(val: string) {
        this.itemsRequest[this.numberElNow].answer = val;
    }

    setTryCount(val: number) {
        this.itemsRequest[this.numberElNow].tryCount = val;
    }

    next() {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow < 0) return;
        this.clearAfterAnswer();
        // this.clearInt();
        this.showHint = false;
        let firsStart = true;
        let elDate = this.numberElNow;
        let countElementNow = this.countElementsOneTime;
        for (; elDate % this.countElementsOneTime !== 0; elDate++) {
            countElementNow--;
        }
        for (; elDate < this.itemsRequest.length &&
               (elDate % this.countElementsOneTime !== 0 || firsStart === true); elDate++) {
            firsStart = false;
            if (!this.itemsRequest[elDate].timeElement)
                this.itemsRequest[elDate].timeElement = 0;
            this.itemsRequest[elDate].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countElementNow;
        }
        this.numberElNow += this.countElementsOneTime;
        this.lastDate = new Date();
        if (this.numberElNow >= this.itemsRequest.length) {
            this.finishTrainingClick();
        }
    }

    back(): void {
        if (this.timerAnswer)
            clearInterval(this.timerAnswer);
        if (this.numberElNow <= 0) {
            return;
        }
        this.clearAfterAnswer();
        // this.clearInt();
        this.showHint = false;
        let firsStart = true;
        let elm1 = this.numberElNow;
        let countNow = 0;//Время
        for (; elm1 % this.countElementsOneTime !== 0 || firsStart === true; elm1--) {
            firsStart = false;
            countNow++;
        }
        firsStart = true;
        for (; elm1 < this.itemsRequest.length &&
               (elm1 % this.countElementsOneTime !== 0 || firsStart === true); elm1++) {
            firsStart = false;
            if (!this.itemsRequest[elm1].timeElement)
                this.itemsRequest[elm1].timeElement = 0;
            this.itemsRequest[elm1].timeElement += (new Date().getTime() - this.lastDate.getTime()) / countNow;
        }
        //Показ все остальное
        this.numberElNow = this.numberElNow - countNow;
        this.lastDate = new Date();
    }

    clearAfterAnswer() {
        this.isClickAnswer = false;
    }

    clickAnswer(answer: string) {
        this.itemsRequest[this.numberElNow].answer = answer;
        this.isClickAnswer = true;
        this.timerAnswer = setInterval(this.next, 1000);
    }

    checkAnswer(answer: string): boolean | undefined {
        if (!this.isClickAnswer)
            return undefined;
        if (this.model.items[this.numberElNow].nameRus === answer)
            return true;
        if (this.itemsRequest[this.numberElNow].answer !== answer)
            return undefined;

        return this.model.items[this.numberElNow].nameRus === answer;
    }

    clickHint() {
        this.showHint = !this.showHint;
    }

    openShowHint(): void {
        this.showHint = true;
    }

    get view(): JSX.Element {
        if (this.itemsRequest.length <= this.numberElNow)
            return <Loading/>
        let model = this.model.items[this.numberElNow];

        if (model.itemType === AdjectiveTrainingItemType.Member) {
            return <div className="training-learn-language">
                <TitleComponent text={model.nameRus} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                <TitleComponent text={model.translationRus} typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.description}/>
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.SelectAnswer) {
            return <div className="training-learn-language">
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                <div className="container-answers">
                    {
                        model.answers?.map(x => <ChooseAnswerTrainingLearnLanguage key={x}
                                                                                   click={this.clickAnswer}
                                                                                   answer={x}
                                                                                   disabled={this.isClickAnswer}
                                                                                   checkAnswer={this.checkAnswer(x)}/>)
                    }
                </div>
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Name) {
            return <div className="training-learn-language">
                {
                    this.showHint &&
                    <TitleComponent key={model.nameRus}
                                    text={model.nameRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.bigTitle}/>
                }
                {
                    <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                }
                {
                    this.showHint &&
                    <TitleComponent key={model.translationRus}
                                    text={model.translationRus}
                                    typePosition={TypePositionText.center}
                                    typeTitle={TypeTitle.description}/>
                }
                {
                    this.model.stage === TrainingMemoryRussianStage.IncodeCoding &&
                    <CheckTextComponent key={"CheckTextComponent" + model.translationRus}
                                        answer={this.itemsRequest[this.numberElNow].answer}
                                        tryCount={this.itemsRequest[this.numberElNow].tryCount}
                                        correctAnswer={model.translationRus}
                                        changeText={this.setUserAnswer}
                                        setTryCount={this.setTryCount}
                                        openShowHint={this.openShowHint}/>
                }
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Image) {
            return <div className="training-learn-language">
                <TitleComponent key={model.nameRus}
                                text={model.nameRus}
                                typePosition={TypePositionText.center}
                                typeTitle={TypeTitle.bigTitle}/>
                {
                    this.showHint &&
                    <>
                        <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                        <TitleComponent key={model.translationRus}
                                        text={model.translationRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.description}/>
                    </>
                }
            </div>
        }
        if (model.itemType === AdjectiveTrainingItemType.Audio) {
            return <div className="training-learn-language">
                <AudioComponent key={"audio_" + model.audio} src={model.audio}/>
                {
                    this.showHint &&
                    <>
                        <TitleComponent key={model.nameRus}
                                        text={model.nameRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.bigTitle}/>
                        <ImageComponent key={model.image} src={model.image} alt={model.nameRus}/>
                        <TitleComponent key={model.translationRus}
                                        text={model.translationRus}
                                        typePosition={TypePositionText.center}
                                        typeTitle={TypeTitle.description}/>
                    </>
                }
            </div>
        }
        return <></>;
    }

    get positionInfo(): string {
        return Math.ceil(this.numberElNow / this.countElementsOneTime) + 1 +
            " / " + Math.ceil(this.itemsRequest.length / this.countElementsOneTime);
    }

    finishTrainingClick() {
        this.finishTraining({
            id: this.model.id,
            items: this.itemsRequest
        });
    }
}