import {CustomComponent} from "../../../helpers/CustomComponent";
import {observer} from "mobx-react";
import {TopUserHead} from "../../topUsers/TopUserHead";
import {TopUsersSorting} from "../../topUsers/TopUsersStore";
import React from "react";
import {UserAdminStore} from "../store/UserAdminStore";
import {AvatarWithMnemocard} from "../../user/AvatarWithMnemocard";
import {Loading} from "../../shared/Loading";

interface Props {
    store: UserAdminStore;
}

@observer
export class UsersAdminComponent extends CustomComponent<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.clickHead = this.clickHead.bind(this)
    }

    componentDidMount() {
        this.props.store.baseStore.init();
    }

    clickHead(customAttr: string){

    }

    render() {
        return <>
            {/*<InputSearchCustom changeAndRequest={this.store.changeNameAndRequest}/>*/}

            <div className="results-table-header">
                <div className="td-number">№</div>
                <div className="td-name">Имя</div>
                <TopUserHead title={"Баллы"}
                             text={"Баллы"}
                             className={"td_point_all"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.all}
                             status={false}/>

                <TopUserHead title={"Вход"}
                             text={"Вход"}
                             className={"td_point_month"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.month}
                             status={false}/>

                <TopUserHead title={"Регистрация"}
                             text={"Регистрация"}
                             className={"td_point_lastmonth"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevMonth}
                             status={false}/>

                <TopUserHead title={"Стат"}
                             text={"Стат"}
                             className={"td_point_day"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.day}
                             status={false}/>

                <TopUserHead title={"Покупки"}
                             text={"Покупки"}
                             className={"td_point_lastday"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevDay}
                             status={false}/>

                <TopUserHead title={"Настройки"}
                             text={"Настройки"}
                             className={"td_point_lastday"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevDay}
                             status={false}/>

                <TopUserHead title={"Забанить"}
                             text={"Забанить"}
                             className={"td_point_lastday"}
                             onclick={this.clickHead}
                             customAttr={TopUsersSorting.prevDay}
                             status={false}/>
            </div>

            <section id="result-lines">
                {
                    !this.props.store.baseStore.models.IsLoadedModel() &&
                    <Loading/>
                }
                {
                    this.props.store.baseStore.models.Model?.map((x, y) =>
                        <div className="results-table-userinfo" key={x.id}>
                            <div className="results-table-number td-number">{y + 1}</div>
                            <AvatarWithMnemocard
                                name={x.fullname}
                                image={x.img}
                                isPremium={x.isPremium}
                                link={x.link}
                                rank={x.rank}/>
                            <div className="td_point_month">{x.balls}</div>
                            <div className="td_point_month">{x.dateLastLoginTime.toString()}</div>
                            <div className="td_point_month">{x.dateRegistration.toString()}</div>
                            <div className="td_point_month">{x.dateRegistration.toString()}</div>
                            <div className="td_point_month"></div>
                            {/*<div className="td_point_month">{x.endDate.toString()}</div>*/}
                        </div>
                    )
                }
            </section>
        </>;
    }
}