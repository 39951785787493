import React, {Component} from 'react'
import './answerTraining.scss'
import './ans-app-images.scss'
import './ans-app-cards.scss'
import {Loading} from "../../shared/Loading";
import {observer} from "mobx-react";
import {AnswerTrainingBaseStore} from "../store/AnswerTrainingBaseStore";
import StopwatchService from "../../../helpers/StopwatchService";
import KeyboardManager from "../../../core/keyboardManager/adapters/KeyboardManager";
import {CustomButton} from "../../../helpers/CustomButton";
import {AnswerTrainingStore} from "./AnswerTrainingStore";

interface Props {
    store: AnswerTrainingBaseStore | undefined
}

@observer
export default class AnswerTrainingComponent extends Component<Props, {}> {
    private store : AnswerTrainingStore;
    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new AnswerTrainingStore(this.props.store);
    }

    componentDidMount() {
        KeyboardManager.add('ArrowLeft', this.props.store!.prevElement);
        KeyboardManager.add('ArrowRight', this.props.store!.nextElement);
        StopwatchService.start()
        this.store.init();
    }

    componentWillUnmount() {
        KeyboardManager.remove('ArrowLeft', this.props.store!.prevElement);
        KeyboardManager.remove('ArrowRight', this.props.store!.nextElement);
        StopwatchService.clearTimer()
        this.store.unInit();
    }

    render() {
        if (!this.props.store)
            return <><Loading/></>
        return (
            <div className="answer-training">
                <div id="contener-2">
                    <div id="ans-box" className="clearfix">
                        {this.props.store.service.view}
                    </div>
                    <div id="ans_display" className="ans-container">
                        {this.props.store.service.viewCustom}
                    </div>
                </div>
                <CustomButton className="watch-res" text={this.store.textWatchRes} onclick={this.store.onFinish}/>
            </div>
        )
    }
}