import React, {Component, CSSProperties} from "react";
import './avatar.css'
import MnemocardStore from "../mnemocard/store/MnemocardStore";

type PropsAvatar = {
    fullname: string,
    img: string,
    link: string,
    width?: string | undefined,
    height?: string | undefined,
    style?: CSSProperties | undefined,
    canClickMnemocard?: boolean,
    addClass?: string;
}

export class Avatar extends Component<PropsAvatar, {}> {
    constructor(props: Readonly<PropsAvatar>) {
        super(props);
        this.stringToColor = this.stringToColor.bind(this);
        this.showMnemocard = this.showMnemocard.bind(this);
    }

    stringToColor(str: string) {
        let hash = 0;
        let color = '#';
        let i;
        let value;
        if (str === undefined || str === null)
            str = '';
        str = str.replace(' ', ''); // todo fix it

        if (!str) {
            return color + '333333';
        }

        for (i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        for (i = 0; i < 3; i++) {
            value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }

        return color;
    }

    showMnemocard() {
        if (this.props.canClickMnemocard === false)
            return;
        MnemocardStore.setLink(this.props.link)
    }

    render() {
        if (this.props.img === null || this.props.img === "")
            return <div className="avatar-component"><div className={"users-info-avatar " + this.props.addClass}
                        onClick={this.showMnemocard}
                        style={{
                            backgroundColor: this.stringToColor(this.props.fullname),
                            width: this.props.width ?? '50px',
                            height: this.props.height ?? '50px',
                            lineHeight: this.props.height ?? '50px',
                            borderRadius: this.props.height ?? '50px',
                            ...this.props.style,
                        }}><span>{this.props.fullname[0]}</span></div></div>
        return (<div className="avatar-component">
            <img className={"small-avatar "+ this.props.addClass} src={'https://powermemory.io' + this.props.img}
                 onClick={this.showMnemocard}
                 style={{
                     width: this.props.width ?? '50px',
                     height: this.props.height ?? '50px',
                     lineHeight: this.props.height ?? '50px',
                     borderRadius: this.props.height ?? '50px',
                     ...this.props.style,
                 }}
                 alt={this.props.fullname}/>
            </div>
        )
    }
}