import {makeAutoObservable, observable, action} from "mobx";
import {LoadingModel} from "../../shared/LoadingModel";
import {SmallPostModel} from "../model/SmallPostModel";
import {GetRequest} from "../../../helpers/Request";

export class PostsStore{

    @observable
    model: LoadingModel<SmallPostModel[]> = LoadingModel.createLoaded();

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.fetchRequest()
    }

    somePosts(){
        this.fetchRequest();
    }

    @action
    setModel(model : LoadingModel<SmallPostModel[]>){
        this.model.IsLoaded = true;
        if (!this.model.Model){
            this.model = model;
        }else{
            // todo дописать чтобы всех показать
            for (let i = 0; i< model.Model!.length; i++){
                console.log(model.Model![i])
                this.model.Model!.push(model.Model![i]);
            }
        }
    }

    fetchRequest() {
        this.model.IsLoaded = false;
        GetRequest<SmallPostModel[]>('/Post/Posts?now='+this.model.Model?.length ?? 0+'&count=4')
            .then(x => this.setModel(x))
    }
}