import React, {Component} from 'react'
import LoadingOverlay from 'react-loading-overlay-ts';


export class Loading extends Component<{}, {}> {

    render() {
        return (
            <div className="loading-page" style={{top: 0, height: '100vh', width: '100vw', position: 'fixed', zIndex: 10}}>
                <LoadingOverlay
                    active={true}
                    spinner
                    text='Загрузка...'
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px',
                            '& svg circle': {
                                stroke: 'rgba(0, 0, 0, 0.7)'
                            }
                        }),
                        wrapper: {
                            width: '400px',
                            height: '400px',
                            // overflow: active ? 'hidden' : 'scroll'
                        }
                    }}
                    // className="loadingOverlay"
                >
                </LoadingOverlay>
            </div>
            // <> Загрузка </>
        )
    }
}
