import React from 'react'
import './trainingLearnLanguageMemberWriteComponent.scss'
import {observer} from "mobx-react";
import TrainingLearnLanguageMemberStore from "./store/TrainingLearnLanguageMemberStore";
import StopwatchService from "../../helpers/StopwatchService";
import AuthorizationStore from "../authorization/store/AuthorizationStore";
import {CustomButton} from "../../helpers/CustomButton";
import Modal from "../modal/ReactModal";
import {
    StepTrainingRussianLanguage,
    TrainingMemoryRussianMemberViewModel,
    TrainingMemoryRussianWriteRequestViewModel
} from "./model/LearnLanguageMemberModel";
import {ReactModalStore} from "../modal/ReactModalStore";
import {CustomComponent} from "../../helpers/CustomComponent";
import {BottomBlockComponent} from "../../helpers/bottomBlock/BottomBlockComponent";
import {CustomButtonView} from "../training/components/CustomButtonView";


interface Props {
    finishTrainingRequest: (model: TrainingMemoryRussianWriteRequestViewModel) => void,
    model: TrainingMemoryRussianMemberViewModel
}
@observer
export class TrainingLearnLanguageMemberComponent extends CustomComponent<Props, {}> {
    store: TrainingLearnLanguageMemberStore;
    private readonly reactModalStore: ReactModalStore = new ReactModalStore();

    constructor(props: Readonly<Props>) {
        super(props);
        this.store = new TrainingLearnLanguageMemberStore(this.props.finishTrainingRequest,
            this.props.model);
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.closeTraining = this.closeTraining.bind(this);
        this.showCloseHint = this.showCloseHint.bind(this);
    }

    componentDidMount() {
        AuthorizationStore.setShowLayoutHead(false);
        StopwatchService.start();
        this.store.init();
        this.reactModalStore.init();
    }
    componentWillUnmount() {
        this.reactModalStore.unInit();
        this.store.unmount();
        StopwatchService.clearTimer()
        AuthorizationStore.setShowLayoutHead(true);
    }

    back() {
        this.store.back()
    }

    next() {
        this.store.next()
    }
    closeTraining(){
        window.location.reload();
    }
    showCloseHint(){
        this.store.clickHint()
    }

    render() {
        return (
            <div className="training-learn-language">
                <Modal store={this.reactModalStore}>
                    <div id="dialog-form" title="Закрыть">
                        <p><span className="ui-icon ui-icon-alert"
                                 style={{float: 'left', margin: '12px 12px 20px 0'}}>
                        </span>Вы уверены что хотите завершить тренировку?</p>
                        <div className="dialog-form-block-buttons">
                            <CustomButton text="Нет" onclick={this.reactModalStore.close}/>
                            <CustomButton text="Закрыть тренировку" onclick={this.closeTraining}/>
                            {
                                ((this.store?.numberElNow ?? 0) >= 9) &&
                                <CustomButton text="Показ результатов" onclick={this.store.finishTrainingClick}/>
                            }
                        </div>
                    </div>
                </Modal>

                <header id="header-training">
                    <div id="header-training-box">
                        {
                            this.store.model.step === StepTrainingRussianLanguage.remembersWords &&
                            <div id="show-me-the-code" style={{backgroundImage: 'none'}}></div>
                        }
                        {
                            this.store.model.step !== StepTrainingRussianLanguage.remembersWords &&
                            <div id="show-me-the-code" onClick={this.showCloseHint} title="Посмотреть образ"></div>
                        }
                        <div id="timer-abcd">{StopwatchService.currentTimeStr}</div>
                        <div id="f-finish" onClick={this.reactModalStore.open} title="Закрыть тренировку"></div>
                    </div>
                </header>
                <div className="language-block">
                    {this.store.view}
                </div>
                {
                    false &&
                    <BottomBlockComponent className="center-bottom">
                        {/*<div className="position-info-v2">{this.store.positionInfo}</div>*/}
                        {/*<CustomButton text={"Пропустить"} className={"button-skip"} onclick={this.next}/>*/}
                        <CustomButtonView click={()=>{}}/>
                    </BottomBlockComponent>
                }

                <div className="nextback">
                    <input id="back" type="button"/>
                    <label className="back-label" htmlFor="back" onClick={this.back}></label>
                    <input id="next" type="button"/>
                    <label className="next-label" htmlFor="next" onClick={this.next}></label>
                    <span className="position-info coding-position-info">{this.store.positionInfo}</span>
                </div>
            </div>
        )
    }
}