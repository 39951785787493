import React, {Component} from 'react'
import './userName.css'
import AuthorizationStore from "../authorization/store/AuthorizationStore";

interface Props {
    link: string;
    username: string,
    fullname: string,
    rank: string,
    isPremium: boolean
};

export class UserName extends Component<Props, {}> {

    render() {
        return (
            <div className="user-name-and-rank" onClick={AuthorizationStore.openMyMnemoCard}>
                <div className="user-name">
                    {this.props.fullname}
                    {this.props.isPremium && <div className="premium-user" data-title="Премиум пользователь"></div>}
                </div>
                <div className="rank">{this.props.rank}</div>
            </div>
        )
    }
}
