import {makeAutoObservable} from "mobx";


export class CheckTextStore {
    private readonly changeText: (val: string) => void;
    private readonly correctAnswer: string = "";
    answer: string = "";
    tryCount: number
    setTryCount: (val: number) => void;
    private currentStatus: CheckTextStatus = CheckTextStatus.noCheck;
    private readonly openShowHint: () => void;

    constructor(correctAnswer: string,
                answer: string,
                changeText: (val: string) => void,
                openShowHint: () => void,
                tryCount: number,
                setTryCount: (val: number) => void) {
        this.openShowHint = openShowHint;
        this.correctAnswer = correctAnswer;
        this.answer = answer;
        this.changeText = changeText;
        this.tryCount = tryCount;
        this.setTryCount = setTryCount;
        makeAutoObservable(this, {}, {autoBind: true})
    }

    changeAnswer(answer: string) {
        this.answer = answer;
        this.changeText(answer);
        this.emptyStatus();
    }

    get status(): CheckTextStatus {
        return this.currentStatus;
    }

    emptyStatus() {
        this.currentStatus = CheckTextStatus.noCheck;
    }

    checkText() {
        this.currentStatus = this.correctAnswer?.toLowerCase()?.trim()?.replace("ё", "е") ===
        this.answer?.toLowerCase()?.trim().replace("ё", "е")
            ? CheckTextStatus.valid
            : CheckTextStatus.noValid;
        if (this.currentStatus === CheckTextStatus.noValid) {
            this.setTryCount(this.tryCount + 1);
            if (this.tryCount >= 2) {
                this.openShowHint();
            }
        }
    }
}

export enum CheckTextStatus {
    noCheck = 0,
    valid = 1,
    noValid = 2,
}