import {LoadingModel} from "../components/shared/LoadingModel";
import {LoadingUrlModel} from "./models/LoadingUrlModel";

let baseUrl = 'https://powermemory.io';
// let baseUrl = 'https://localhost:5001'; // todo fix it

export async function GetRequest<T>(link: string): Promise<LoadingModel<T>> {
    let req = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    let res: LoadingModel<T> = new LoadingModel<T>();
    let response = await fetch((baseUrl + link), req);
    if (response.ok) {
        res.setModel(await response.json());
        return res;
    }
    res.setError(response.statusText);
    return res;
}

export async function PostRequest<T, E>(link: string, body: E): Promise<LoadingModel<T>> {
    let req = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    let res: LoadingModel<T> = new LoadingModel<T>();
    let response = await fetch((baseUrl + link), req);
    if (response.ok) {
        res.setModel(await response.json());
        return res;
    }
    res.setError(response.statusText);
    return res;
}

export async function PostRequestWithoutResponseBody<E>(link: string, body: E): Promise<Response> {
    let req = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    return await fetch((baseUrl + link), req);
}

export async function GetRequestWithoutResponseBody(link: string): Promise<Response> {
    let req = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
            // 'Content-Type': 'application/json'
        },
    };
    return  await fetch((baseUrl + link), req);
}

export async function UploadImage(file: File): Promise<LoadingUrlModel | null> {
    let data = new FormData();
    data.append('file', file);
    let req = {
        method: "POST",
        body: data,
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    };
    let response = await fetch((baseUrl + "/help/UploadNewFiles"), req);// todo fix it!! api/Upload/UploadImageMinio
    if (response.ok) {
        return await response.json();
    }
    return null;
}
