import {makeAutoObservable, observable} from "mobx";
import {GetRequest, PostRequest} from "../../../helpers/Request";
import {AuthUserModel} from "../models/AuthUserModel";
import {LoadingModel} from "../../shared/LoadingModel";
import {AuthJwtModel} from "../models/AuthJwtModel";
import {ForgotEmailModel} from "../models/ForgotEmailModel";
import {ReactModalStore} from "../../modal/ReactModalStore";
import MnemocardStore from "../../mnemocard/store/MnemocardStore";


class AuthorizationStore {
    reactModalStore: ReactModalStore = new ReactModalStore();
    @observable
    login: string = "";
    @observable
    password: string = "";
    @observable
    rememberMe: boolean = true;
    @observable
    turnRememberPassword: boolean = false;
    @observable
    rememberEmail: string = "";
    @observable
    user: LoadingModel<AuthUserModel> = LoadingModel.createLoaded<AuthUserModel>();
    @observable
    jwtModel: LoadingModel<AuthJwtModel> = LoadingModel.createLoaded<AuthJwtModel>();
    @observable
    showMenu: boolean = false;
    @observable
    showLayoutHead: boolean = true;
    @observable
    showModalBalls: boolean = false;
    @observable
    error: boolean = false;

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        // this.setDefaultLogIn();
        this.jwtModel.Model ={
            expires: localStorage.getItem("expires") ?? "",
            jwt: localStorage.getItem("jwt") ?? "",
            username: localStorage.getItem("username") ?? "",
        };
        this.getUser();
    }

    setShowLayoutHead(showLayoutHead: boolean){
        this.showLayoutHead = showLayoutHead;
    }

    openModalAuth(){
        this.reactModalStore.open()
    }

    setShowMenu(modalState : boolean){
        this.showMenu = modalState;
    }

    setLogin(val: string) {
        this.setError(false)
        this.login = val;
    }

    setChangeRememberEmail(rem: boolean) {
        this.turnRememberPassword = rem;
    }

    setShowModalBalls(rem: boolean) {
        this.showModalBalls = rem;
    }

    openMyMnemoCard(){
        if (this.user.Model)
            MnemocardStore.setLink(this.user.Model.link)
    }

    setUserAuth(user: LoadingModel<AuthJwtModel>){
        this.jwtModel = user;
        this.jwtModel.IsLoaded = true;
        localStorage.setItem("jwt", this.jwtModel.Model!.jwt);
        localStorage.setItem("expires", this.jwtModel.Model!.expires);
        localStorage.setItem("username", this.jwtModel.Model!.username);
        this.updateUser()?.then(async x => {
            if (x === null)
                return;
            if (x?.isAuth === false){
                await this.logOut();
            }
            if (x?.isAuth === true){
                window.location.reload();
            }
        })
        this.setDefaultLogIn();
    }


    private setDefaultLogIn() {
        this.reactModalStore.close()
        this.login = "";
        this.password = "";
        this.rememberMe = true;
    }

    setPassword(val: string) {
        this.password = val;
        this.setError(false)
    }

    private setUser(user: LoadingModel<AuthUserModel>) {
        if (user.Model!.userId == null){
            // this.logOut();
            return;
        }
        this.user = user;
    }
    setError(is: boolean){
        this.error = is;
    }
    setJwtLoaded(is: boolean){
        this.jwtModel.IsLoaded = is
    }
    setUserLoaded(is: boolean){
        this.user.IsLoaded = is
    }

    logIn() {
        console.log('login!')
        if (!this.jwtModel.IsLoadedModel())
            return;
        this.setJwtLoaded(false)
        this.setError(false)
        PostRequest<AuthJwtModel, {}>('/Authorization/LoginToken',
            {"Email": this.login, "Password": this.password, "RememberMe": this.rememberMe})
            .then(x => this.setUserAuth(x))
            .catch(_ => this.setError(true))
            .finally(() => this.setJwtLoaded(true));
    }

    getUser() {
        if (!this.isAuthJwt())
            return null; // todo return promise
        this.setUserLoaded(false)
        return GetRequest<AuthUserModel>('/api/AuthUser/GetUser')
            .then(x => {
                this.setUser(x);
                return x.Model;
            })
            .catch((x) => {
                console.log('catch', x);
                alert('Извините что-то пошло не так')
                // return {};
            })
            .finally(() => this.setUserLoaded(true));
    }

    updateUser(){
        return this.getUser();
    }
    sendRememberEmail(){
        PostRequest<ForgotEmailModel, ForgotEmailModel>('/Settings/ForgotPasswordPost', {email: this.login})
            .then(x => alert(x.Model))
            .then(x => console.log(x))
    }

    async logOut() {
        localStorage.setItem("jwt", "");
        localStorage.setItem("expires", "");
        localStorage.setItem("username", "");
        await this.updateUser();
        window.location.reload();
    }

    isAuthJwt(): boolean {
        let jwt = localStorage.getItem('jwt');
        return jwt !== "" && jwt !== null;
    }

    get checkAuthMenu(){
        return this.isAuthJwt() && this.user.IsLoaded && this.user.Model;
    }
}

export default new AuthorizationStore();