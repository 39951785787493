import './footer.scss'
import {CustomComponent} from "../../helpers/CustomComponent";
import {Link} from "react-router-dom";
import TimeSite from "./TimeSite";


export default class Footer extends CustomComponent<{}, {}>{

    render() {
        return <footer>
            <div id="container-footer">
                {/*@*Подключитесь к сообществу*@*/}
                <div id="community-box">
                    <div className="cb-info">
                        <div id="cb-info-h">Присоединяйтесь к сообществу мнемонистов</div>
                        <div id="cb-info-h2">Все о развитии памяти для новичков и практикующих мнемонистов</div>
                    </div>
                    <Link to="http://t.me/powermemory" className="cb-info" id="cb-info-bottom">
                        <div id="telegram-link"></div>
                        <div id="sub-telegram">ВСТУПИТЬ</div>
                    </Link>
                </div>

                {/*@*Навигация*@*/}
                <ul>
                    <li><Link to="https://youtube.com/channel/UCt2vKpiph65SVfDl6SnDo3Q">Полезные видео на YouTube</Link></li>
                    <li><Link to="https://t.me/alexgruk">Связь</Link></li>
                    <li><Link to="/motivation">Система мотивации</Link></li>
                    <li><Link to="/manifest">Манифест</Link></li>
                    <li><Link to="/rules">Пользовательское соглашение</Link></li>
                    <li><Link to="/privacyPolicy">Политика конфиденциальности</Link></li>

                </ul>

                {/*@*Лого*@*/}
                <div className="head__logo">
                    <a href="/">
                        <img alt="Powermemory" src="https://powermemory.io/images/footer/f_logo-w.png" />
                    </a>
                </div>

                <div id="copyright">© PowerMemory™ 2013-2024 Все права защищены</div>

                {/*@*Время*@*/}
                <TimeSite />

                {/*// <!-- Кнопки смены ЯЗЫКА -->*/}
                {/*<input id="lang_ru" type="radio" name="for_langs"/>*/}
                {/*<label id="lang_ru-label" htmlFor="lang_ru">RU</label>*/}

                {/*<input id="lang_en" type="radio" name="for_langs"/>*/}
                {/*<label id="lang_en-label" htmlFor="lang_en">EN</label>*/}

                {/*// <!-- CОЦ СЕТИ -->*/}
                <div id="social">
                    <a href="https://vk.com/powermemory">
                        <img alt="Группа ВКонтакте" title="Группа ВКонтакте" src="https://powermemory.io/images/footer/vk_logo.png"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCt2vKpiph65SVfDl6SnDo3Q">
                        <img alt="Основной канал YouTube" title="Основной канал YouTube"
                             src="https://powermemory.io/images/footer/you_logo.png" />
                    </a>
                    <a href="https://www.instagram.com/powermemory/">
                        <img alt="Страница Instargam" title="Страница Instargam" src="https://powermemory.io/images/footer/g_logo.png"/>
                    </a>
                    <a href="https://www.X.com/powermemory.ru">
                        <img alt="Группа в X" title="Группа в X" src="https://powermemory.io/images/footer/X_logo.png"/>
                    </a>
                </div>

                {/*// <!-- ПРИЛОЖЕНИЯ ссылки -->*/}
                {/*<div id="app">*/}
                {/*    <a href="#">*/}
                {/*        <img alt="Приложение для Android" title="Приложение для Android"*/}
                {/*             src="https://powermemory.io/images/footer/google_play.png"/>*/}
                {/*    </a>*/}
                {/*    <a href="#">*/}
                {/*        <img alt="Приложение для IOS" title="Приложение для IOS" src="https://powermemory.io/images/footer/app_store.png"/>*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*<address>@Localizer["Authors"]</address>*/}
            </div>
        </footer>
    }
}